import React from 'react'
import { Link } from 'gatsby'
import BetterIcon from '@/components/utils/BetterIcon'
import rightChevron from '@/icons/ui-right-chevron.svg'

export default ({ uri }) => (
	<Link
		className="text-sm-fluid font-bold hover:text-orange focus:text-orange outline-none trans trans-color inline-flex items-center"
		to={uri}
	>
		<span className="mr-0-5">read</span>
		<span className="text-teal">
			<BetterIcon icon={rightChevron} className="w-10" />
		</span>
	</Link>
)
