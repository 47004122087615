import React from 'react'
import { Link } from 'gatsby'
import BetterIcon from '@/components/utils/BetterIcon'
import pauseIcon from '@/icons/ui-pause.svg'
import playIcon from '@/icons/ui-play.svg'

const Tag = ({ to, children, onClick, className }) =>
	to ? (
		<Link
			to={to}
			className={`circle-animate-wrap circle-animate ${className}`}
			onClick={onClick}
		>
			{children}
		</Link>
	) : (
		<button
			aria-label="Play/Pause"
			type="button"
			className={`circle-animate-wrap circle-animate ${className}`}
			onClick={onClick}
		>
			{children}
		</button>
	)

export default ({
	onClick,
	playing,
	to,
	sizeClass = 'w-80 h-80',
	iconSizeClass = 'h-25 w-full'
}) => (
	<Tag
		to={to}
		onClick={onClick}
		className={`${sizeClass} inline-flex items-center justify-center border-3 rounded-circle border-orange focus:bg-white focus:text-black trans outline-none`}
	>
		<BetterIcon
			icon={!playing ? playIcon : pauseIcon}
			className={iconSizeClass}
		/>
		<span className="visuallyhidden">{!playing ? 'Play' : 'Pause'}</span>
	</Tag>
)
