module.exports = {
	siteTitle: 'we are straightline',
	title: 'we are straightline',
	description:
		'the home of sounds and videos brought to you by the Prison Radio Association, the award-winning people behind National Prison Radio.',

	facebookAppId: '1630865587228647',
	twitterHandle: 'PrisonRadioUK',
	fallbackImage:
		'https://straightline-api.mudbank.uk/assets/images/Clint-4.jpg',

	social: {
		twitter: {
			title: 'wearestraightline',
			description: '',
			image: {
				url: 'https://straightline-api.mudbank.uk/assets/images/Clint-4.jpg'
			}
		},
		facebook: {
			title: 'wearestraightline',
			description: '',
			image: {
				url: 'https://straightline-api.mudbank.uk/assets/images/Clint-4.jpg'
			}
		}
	}
}
