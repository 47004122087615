export const setupPage = ({
	setPageType,
	playing,
	showUi,
	setAudioBarVisibility
}) => {
	if (!playing) {
		setAudioBarVisibility(false)
	} else if (!showUi) {
		setAudioBarVisibility(true)
	}

	setPageType(null)
}
