import React, { Component } from 'react'
import { Link } from 'gatsby'
import Icon from '@/components/utils/Icon'
import Readmore from '@/components/utils/Readmore'
import Image from '@/components/utils/Image'
import Tags from '@/components/Tags/Tags'
import audio from '@/icons/ui-audio.svg'
import video from '@/icons/ui-video.svg'
import blog from '@/icons/ui-blog.svg'
import BetterIcon from '@/components/utils/BetterIcon'
import BigPlayPause from '@/components/utils/BigPlayPause'
import playIcon from '@/icons/ui-play.svg'

const icons = {
	audio,
	video,
	blog
}

export default class PostThumb extends Component {
	shouldComponentUpdate = () => false

	render() {
		const {
			type,
			title,
			duration,
			tags,
			image,
			subtitle,
			uri,
			readmore,
			durationColor = 'text-grey-light',
			withSubTitle = false
		} = this.props

		const icon = type === 'video' ? 'video' : type === 'blog' ? 'blog' : 'audio'
		return (
			<div>
				<Link
					to={uri}
					className="relative mb-0-5 block group outline-none"
					tabIndex="-1"
				>
					<Image fluid={image} />
					{ type && (
						<div className="absolute pin-t pin-l flex items-center p-0-5 md:py-0-5 md:px-0-75 bg-grey z-20">
							<span className="text-teal mr-0-5">
								<BetterIcon
									icon={icons[icon]}
									className="w-15 lg:w-25"
									style={{ maxHeight: 15 }}
								/>
							</span>
							<span className="leading-none font-bold text-ms--4 lg:text-ms--1 text-white">
								{type}
							</span>
						</div>
					)}
					<div className="bg-black-50 absolute pin w-full h-full z-20 flex items-end p-1 opacity-0 trans group-hover:opacity-100">
						{type !== 'blog' && (
							<div className="absolute-center z-20">
								<BigPlayPause
									sizeClass="w-60 h-60 mb-1"
									iconSizeClass="h-15 w-full"
								/>
							</div>
						)}
					</div>
				</Link>

				<h2 className="text-lg-fluid font-bold mb-0-5 lowercase">
					<Link
						className="hover:text-orange focus:text-orange outline-none trans"
						to={uri}
					>
						{title}
					</Link>
				</h2>
				{withSubTitle && (
					<p className={`${durationColor} text-xs-fluid mb-1`}>{subtitle}</p>
				)}

				{duration && (
					<p className={`${durationColor} text-xs-fluid mb-0-5`}>{duration}</p>
				)}
				{tags && <Tags tags={tags} />}

				{readmore && (
					<div className="mt-1">
						<Readmore uri={uri} />
					</div>
				)}
			</div>
		)
	}
}
