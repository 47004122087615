import React, { Component } from 'react'
import { Link } from 'gatsby'
import { slugify } from '@/utils/strings'

export default class Tags extends Component {
	shouldComponentUpdate = () => false

	render() {
		const { tags } = this.props
		return (
			<ul className="list-reset flex flex-wrap text-teal font-bold text-xxs-fluid -ml-0-25">
				{tags.map(({ title, id }) => (
					<li key={id} className="mr-0-25">
						<Link
							to={`/tags/${slugify(title)}`}
							className="outline-none hover:text-orange focus:text-orange trans trans-color focus-box trans py-0-25 px-0-25 lowercase"
						>
							#{title}
						</Link>
					</li>
				))}
			</ul>
		)
	}
}
