import React, { Component } from 'react'
import Helmet from 'react-helmet'
import config from '@/site.config'

export default class SEO extends Component {
	render() {
		const {
			siteTitle,
			description: fallbackDescription,
			facebookAppId,
			twitterHandle,
			fallbackImage
		} = config
		const {
			description,
			image,
			socialDesc,
			socialTitle,
			url,
			article,
			title
		} = this.props

		const schemaOrgJSONLD = [
			{
				'@context': 'http://schema.org',
				'@type': 'WebSite',
				url,
				name: title,
				alternateName: siteTitle
			}
		]

		if (article) {
			schemaOrgJSONLD.push(
				{
					'@context': 'http://schema.org',
					'@type': 'BreadcrumbList',
					itemListElement: [
						{
							'@type': 'ListItem',
							position: 1,
							item: {
								'@id': url,
								name: title,
								image
							}
						}
					]
				},
				{
					'@context': 'http://schema.org',
					'@type': 'BlogPosting',
					url,
					name: title,
					alternateName: siteTitle,
					headline: title,
					image: {
						'@type': 'ImageObject',
						url: image
					},
					description: description || fallbackDescription
				}
			)
		}

		return (
			<Helmet title={`${title} | ${siteTitle}`}>
				<meta name="description" content={description} />
				{image && <meta name="image" content={image} />}
				<script type="application/ld+json">
					{JSON.stringify(schemaOrgJSONLD)}
				</script>
				<meta property="og:url" content={url} />
				{article ? <meta property="og:type" content="article" /> : null}
				<meta property="og:title" content={socialTitle} />
				<meta
					property="og:description"
					content={socialDesc || fallbackDescription}
				/>
				<meta property="og:image" content={image || fallbackImage} />
				<meta property="fb:app_id" content={facebookAppId} />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:creator" content={twitterHandle} />
				<meta name="twitter:title" content={socialTitle} />
				<meta
					name="twitter:description"
					content={socialDesc || fallbackDescription}
				/>
				<meta name="twitter:image" content={image || fallbackImage} />
				<link rel="canonical" href={url} />
			</Helmet>
		)
	}
}
