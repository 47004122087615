import React, { Component, Fragment } from 'react'
import * as R from 'ramda'
import { connect } from 'react-redux'
import { graphql } from 'gatsby'
import SEO from '@/components/SEO/SEO'
import Image from '@/components/utils/Image'
import PostWysiwyg from '@/components/PostWysiwyg/PostWysiwyg'
import PostThumb from '@/components/PostThumb/PostThumb'
import Social from '@/components/Social/Social'
import Tags from '@/components/Tags/Tags'
import { setupPage } from '@/utils/common'

/* eslint-disable */
import {
	relatedBlog,
	relatedAudio,
	relatedVideo,
	optimisedImage
} from '@/queries'
/* eslint-enable */

import {
	pluckPrepItem,
	prepRelatedContent,
	prepItem,
	getSeoImage
} from '@/utils'

class BlogPost extends Component {
	constructor(props) {
		super(props)

		setupPage(props)
	}

	render() {
		const {
			data: {
				craft: { post, related }
			},
			location
		} = this.props

		const { bannerImage } = post
		const banner = bannerImage ? bannerImage[0] : null

		const {
			image,
			postDate,
			title,
			tags,
			seo,
			subtitle,
			contentBlocks: tmpContentBlocks
		} = pluckPrepItem([post])

		const sidebar = R.compose(
			R.take(3),
			prepRelatedContent
		)(related)

		const contentBlocks = prepItem(tmpContentBlocks)

		const {
			socialMetaTitle,
			socialMetaDescription,
			seoTitle,
			seoDescription
		} = post
		const seoImage = getSeoImage(post)

		return (
			<Fragment>
				<SEO
					title={seoTitle || post.title}
					description={seoDescription || subtitle}
					socialTitle={socialMetaTitle || post.title}
					socialDesc={socialMetaDescription || subtitle}
					image={seoImage}
					url={location.href}
				/>
				{banner && (
					<div className="max-h-450 overflow-hidden">
						<Image fluid={{ ...banner.optimisedBanner, title: banner.title }} />
					</div>
				)}
				<div
					className={`bg-white ${
						banner ? 'pt-3' : 'pt-5'
					} pb-2 lg:pb-4 lg:pt-5`}
				>
					<div className="wrapper">
						<div className="lg:flex lg:justify-between">
							<section className="lg:w-16/24">
								<header className="mb-2 lg:mb-3">
									<time className="text-xs-fluid text-aluminium -mt-1 mb-1 inline-block">
										{postDate}
									</time>
									<h1 className="lowercase mb-1 text-xlh-fluid text-black font-bold relative">
										<span className="absolute block h-5 w-screen vert-center pin-r-100 bg-teal mr-2" />
										{title}
									</h1>
									{subtitle && (
										<p className="text-xs-fluid text-black mb-1">{subtitle}</p>
									)}
								</header>
								<PostWysiwyg>
									{contentBlocks.map(item => (
										<Fragment key={item.id}>
											{item.type === 'contentblocksrichtext' && (
												<div
													className="text-black mb-1 lg:mb-2"
													dangerouslySetInnerHTML={{ __html: item.body }}
												/>
											)}
											{item.type === 'contentblocksquote' && (
												<blockquote className="mt-1 mb-1 text-h5-fluid lg:my-3">
													{item.quote}
												</blockquote>
											)}
											{item.type === 'contentblocksimage' && (
												<div className="mb-1 lg:mb-2">
													<Image fluid={item.image} />
												</div>
											)}
										</Fragment>
									))}
								</PostWysiwyg>

								<div className="mb-4">
									<div className="sm:flex sm:justify-between text-teal">
										<div className="mb-1 sm:mb-0">
											<Tags tags={tags} />
										</div>
										<Social
											title={title}
											url={location.href}
											color="text-teal"
										/>
									</div>
								</div>
							</section>
							<aside className="lg:w-5/24 lg:pt-2" offset={10}>
								<h3 className="text-xl-fluid mb-2 text-black font-bold">
									Related Content
								</h3>
								<ul className="list-reset text-black">
									{sidebar.map(({ id, type, ...rest }) => (
										<li key={id} className="mb-2">
											<PostThumb
												{...rest}
												readmore={type === 'blog'}
												type={type}
												durationColor="text-tundora"
												tags={false}
											/>
										</li>
									))}
								</ul>
							</aside>
						</div>
					</div>
				</div>
			</Fragment>
		)
	}
}

const mapState = state => ({
	...state.player
})

const mapDispatch = ({ player: { setPageType, setAudioBarVisibility } }) => ({
	setPageType,
	setAudioBarVisibility
})

export default connect(
	mapState,
	mapDispatch
)(BlogPost)

export const pageQuery = graphql`
	query BlogPostByUri($uri: String!, $searchTags: String!, $id: [Int]!) {
		craft {
			post: entry(uri: $uri) {
				...relatedBlog

				... on Craft_Blog {
					subtitle
					bannerImage {
						title
						... on Craft_ImagesVolume {
							optimisedBanner {
								... on Craft_OptimizedImagesData {
									src
									srcset
									srcWebp
									srcsetWebp
									maxSrcsetWidth
									placeholderWidth
									placeholderHeight
									colorPalette
								}
							}
						}
					}

					seo {
						title
						description

						social {
							twitter {
								title
								image {
									url
								}
								description
							}
							facebook {
								title
								image {
									url
								}
								description
							}
						}
					}
					image {
						... on Craft_ImagesVolume {
							optimisedImage {
								src
								srcset
								srcWebp
								srcsetWebp
								maxSrcsetWidth
								placeholderWidth
								placeholderHeight
								colorPalette
							}

							optimisedBanner {
								src
								srcset
								srcWebp
								srcsetWebp
								maxSrcsetWidth
								placeholderWidth
								placeholderHeight
								colorPalette
							}
						}
					}

					contentBlocks {
						__typename
						... on Craft_ContentBlocksQuote {
							quote
							id
						}
						... on Craft_ContentBlocksRichText {
							body {
								content
							}
							id
						}
						... on Craft_ContentBlocksImage {
							image {
								...optimisedImage
							}
							id
						}
					}
				}
			}

			related: tagsConnection(search: $searchTags) {
				edges {
					relatedEntries(
						section: [blog, audio, video]
						limit: 9
						idNot: $id
						order: "postDate desc"
					) {
						entries {
							...relatedBlog
							...relatedAudio
							...relatedVideo
						}
					}
				}
			}
		}
	}
`

/*



*/
